import { Elm } from './Main.elm';
//import {v4 as uuidv4} from 'uuid';

var app = Elm.Main.init({
  node: document.getElementById('root')
});


// Declare WebSocket.
var socket;
const { v4: uuidv4 } = require('uuid');
//PROD:
const Host = 'poker.robertgorski.info';
const Port = '443';
const Protocol = "wss"
//Local:
//const Host = 'localhost';
//const Port = '7777';
//const Protocol = "ws"
const Token = uuidv4();

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

//initialize new room socket on request
app.ports.initializeBackend.subscribe(function(nickname) {
  socket = new WebSocket(Protocol + "://" + Host + ":" + Port + "/new?name=" + nickname + "&token=" + Token);

  socket.addEventListener("message", function(event) {
    app.ports.messageReceiver.send(event.data);
  });

  socket.addEventListener('close', function(event) {
    app.ports.messageReceiver.send("CL:"+event.reason);
  });

  socket.addEventListener('error', function(event) {
    app.ports.messageReceiver.send("ER:"+event.reason);
  });

  //socket.onopen = () => socket.send("OK:"+Token);
  delay(1000).then(() => app.ports.messageReceiver.send("OK:"+Token));
  
});

//initialize joining room socket on request
app.ports.joinBackend.subscribe(function(nicknameAndRoomId) {
  const params = nicknameAndRoomId.split(":");
  const nickname = params[0];
  const roomId = params[1];
  socket = new WebSocket(Protocol + "://" + Host + ":" + Port + "/join?name=" + nickname + "&room=" + roomId);

  socket.addEventListener("message", function(event) {
    app.ports.messageReceiver.send(event.data);
  });

  socket.addEventListener('close', function(event) {
    app.ports.messageReceiver.send("CL:"+event.reason);
  });

  socket.addEventListener('error', function(event) {
    app.ports.messageReceiver.send("ER:"+event.reason);
  });

  //socket.onopen = () => socket.send("OK:"+Token);
  delay(1000).then(() => app.ports.messageReceiver.send("OK"));
  
});

//copy to clipboard on the JS side of the port
app.ports.copyToClipboard.subscribe(function(roomId) {
  navigator.clipboard.writeText(roomId);
});

// When a command goes to the `sendMessage` port, we pass the message
// along to the WebSocket.
app.ports.sendMessage.subscribe(function(message) {
    if (socket) socket.send(message);
});
